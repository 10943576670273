import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { BarLoader } from "react-spinners";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DownloadPopup from "./DownloadPopup";
import { format } from "date-fns";
import LoadingPopup from "./LoadingPopup";

import { styled } from "@mui/system";

const RedHeartIcon = styled(FavoriteIcon)({
  color: "red",
});

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = () => {
  return (
    <div className="loader">
      <BarLoader color={"#e7004d"} css={override} />
    </div>
  );
};

function getTikTokVideoId(url) {
  const regex = /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/(?:@[\w.-]+\/video\/|v\/|embed\/|)(\d+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
}


const Card = ({
  data,
  loading,
  videoURL,
  setDownloadPopupVisible,
  downloadCommentsLoading,
  setDownloadCommentsLoading,
}) => {
  console.log("🚀 ~ Card ~ videoURL:", videoURL);
  console.log("data>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.", data);

  const showDownloadPopup = () => {
    setDownloadPopupVisible(true);
  };

  const hideDownloadPopup = () => {
    setDownloadPopupVisible(false);
  };

  const [history, setHistory] = useState([]);

  const [getVideoSubsLoading, setGetVideoSubsLoading] = useState(false);
  const [getVideoSubsError, setGetVideoSubsError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [getResponse, setGetResponse] = useState();
  console.log("🚀 ~ getResponse card>>>>>>>>>>>>>>>>>>>>>>>>>:", getResponse);

  const [subtitlesData, setSubtitlesData] = useState([]);
  console.log("🚀 ~ subtitlesData:", subtitlesData);

  const GetVideoSubtitles = async (cursor = 0) => {
    setGetVideoSubsLoading(true);
    setGetVideoSubsError("");
    setSuccessMessage("");

    try {
      const response = await fetch(
        // `https://tc.tokbackup.com/getComments?video_url=${encodeURIComponent(
        `https://bogustokscrpapi.toktools.online/v1/tiktok/video-comments?aweme_id=${getTikTokVideoId(videoURL)}&cursor=${cursor}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_apiKey,
            "Referer": document.referrer,
            "User-Agent": navigator.userAgent,
          },
        }
      );

      const data = await response.json();
      if (data.data && data.data.comments) {
        setGetResponse(data.data);
        setSubtitlesData((pre) => [...data.data.comments, ...pre]);
      }
      if(data.data.has_more == 1){
        GetVideoSubtitles(data.data.cursor)
      }

      if (data.error) {
        setSuccessMessage("");
        setGetVideoSubsError(data.message);
        return null;
      }

      setHistory([data.subtitles]);
      return data.subtitles;
    } catch (error) {
      console.log(error.message);
    } finally {
      setGetVideoSubsLoading(false);
    }
  };

  // subtitleInfos
  const generateCSV = (commentsData, subtitleInfos) => {
    console.log("🚀 ~ generateCSV ~ commentsData107:", commentsData);
    console.log("🚀 ~ generateCSV ~ subtitleInfos108:", subtitleInfos);
    console.log("callllllllllllllllllllllllllllllllllllllllll");
    const commentsCsvContent =
      "data:text/csv;charset=utf-8," +
      "Name,Comments,Likes\n" +
      // "Name,Comments,Likes,Time Posted\n" +
      commentsData
        .map((comment) => {
          // const nickname = comment.user.nickname || "";
          // const nickname = comment?.user?.nickname.replace(/"/g, '""') || "";
          const nickname = (comment.user.nickname || "")
            .replace(/"/g, '""')
            .replace(/#/g, ""); // Remove #

          const text = comment?.text || "";
          const diggCount = comment?.digg_count || "";
          const createTime =
            format(new Date(comment?.create_time * 1000), "dd/MM/yyyy") || "";
          return `"${nickname || ""}","${text || ""}",${diggCount || ""},"${
            createTime || ""
          }"`;
        })
        .join("\n");
    console.log(
      "🚀 ~ generateCSV ~ commentsCsvContent125:",
      commentsCsvContent
    );

    const encodedCommentsUri = encodeURI(commentsCsvContent);
    const commentsLink = document.createElement("a");
    commentsLink.href = encodedCommentsUri;
    commentsLink.download = "comments.csv";
    document.body.appendChild(commentsLink);
    commentsLink.click();
    document.body.removeChild(commentsLink);

    // ===========================================
    // ===========================================
    const lines = subtitleInfos
      .replace(/"subtitles": "|"/g, "")
      .split("\n")
      .map((line) => line.trim())
      .filter(Boolean);

    const timecodesAndSubtitles = lines.slice(2);

    const csvContent =
      "Start Time,End Time,Text\n" +
      timecodesAndSubtitles
        .map((line, index, array) => {
          if (index % 2 === 0) {
            const startTime = line.split(" --> ")[0];
            const endTime = line.split(" --> ")[1];
            const text = array[index + 1];
            return `${startTime}\t${endTime}\t${text.trim()}`;
          }
          return null;
        })
        .filter(Boolean)
        .join("\n");

    const encodedUri2 = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    const link2 = document.createElement("a");
    link2.href = encodedUri2;
    link2.download = "subtitles.csv";
    document.body.appendChild(link2);
    link2.click();
    document.body.removeChild(link2);

    // ===========================================
    // ===========================================
  };

  // generateCSV(subtitlesData, data?.video?.subtitleInfos);

  console.log("🚀 ~ data>>>>>>>>>>:", data);
  let successMessageFirstCall = data?.message == "Success";
  console.log("🚀 ~ successMessageFirstCall:", successMessageFirstCall);
  useEffect(() => {
    GetVideoSubtitles();
  }, [successMessageFirstCall]);

  const downloadComments = async () => {
    showDownloadPopup();
    setDownloadCommentsLoading(true);
    try {
      const response = await fetch(
        `https://tc.tokbackup.com/downloadCommentsCSV?video_url=${encodeURIComponent(
          videoURL
        )}`
      );

      if (!response.ok) {
        console.error(`Failed to download comments: ${response.statusText}`);
        return;
      }

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "comments.csv";
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading comments:", error.message);
    } finally {
      setDownloadCommentsLoading(false);
      hideDownloadPopup();
    }
  };

  function convertTimestamp(timestamp) {
    // console.log("timestamp>>>>>>>>>", timestamp);
    const humanReadableTime = format(new Date(timestamp * 1000), "yyyy-MM-dd");
    // console.log("humanReadableTime>>>>>>>>>", humanReadableTime);
    return humanReadableTime;
  }

  return (
    <div className="sm:w-11/12  m-auto animate__animated animate__fadeInDown ">
      <div
        className="rounded-md md:flex sm:mx-auto mx-2 bg-white lg:py-11 lg:px-9 sm:py-9 sm:px-6 p-3  shadow-lg"
        style={{ maxHeight: "700px", maxWidth: "920px" }}
      >
        <div
          className=" w-2/5 flex-none  text-center overflow-hidden hidden md:flex hover:p-1"
          title=""
        >
          {data?.data?.video ? (
            <img
              src={data?.data?.video?.zoomCover[480]}
              alt=""
              className="rounded-lg object-cover"
            />
          ) : (
            <img
              src="Images\tiktok-comment.jpg"
              alt=""
              className="rounded-lg object-cover"
              style={{ maxHeight: "650px" }}
            />
          )}

          {/* {data?.video_info ? (
            <img
              src={data?.video_info?.video?.cover}
              alt=""
              className="rounded-lg w-full h-auto object-cover"
            />
          ) : (
            <img
              src="Images\tiktok-comment.jpg"
              alt=""
              className="rounded-lg w-full h-auto object-cover"
            />
          )} */}
        </div>
        <div
          className="rounded-md p-2 flex flex-col leading-normal md:ml-3 md:w-3/5 overflow-y-auto"
          style={{ backgroundColor: "rgba(31,41,55,255)", maxHeight: "600px" }}
        >
          {data?.data?.video && getResponse?.has_more == 1 && (
            <LoadingPopup errorMsg={getResponse?.error} />
          )}
          {/* BUTTON */}
          {videoURL && getResponse?.has_more == 0 && subtitlesData?.length > 0 && (
            <div className="md:flex justify-between">
              <button
                type="button"
                className=" inline-block px-2 py-2.5 bg-pink-600 font-bold text-xs leading-tight uppercase rounded shadow-md 
              hover:bg-pink-700 hover:shadow-lg focus:bg-pink-700 focus:shadow-lg focus:outline-none focus:ring-0 
              active:bg-pink-800 active:shadow-lg transition duration-150 ease-in-out 
              text-white bg-gradient-to-r from-[#e7004d]  to-[#a205c3] whitespace-nowrap sm:w-11/12 w-full
                "
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                // onClick={() => {
                //   downloadComments(videoURL);
                // }}
                onClick={() => {
                  // generateCSV(subtitlesData, data?.data?.video?.subtitleInfos);
                  generateCSV(subtitlesData, data?.subtitles);
                }}
                disabled={downloadCommentsLoading}
              >
                {loading ? "loading..." : "Download Comments"}
              </button>
              <button
                type="button"
                className="  inline-block px-2 py-2.5 bg-pink-600 text-white font-bold text-xs leading-tight uppercase rounded shadow-md 
              hover:bg-pink-700 hover:shadow-lg focus:bg-pink-700 focus:shadow-lg focus:outline-none focus:ring-0 
              active:bg-pink-800 active:shadow-lg transition duration-150 ease-in-out
              bg-gradient-to-r from-[#e7004d]  to-[#a205c3] whitespace-nowrap
               md:ml-2 md:mt-0 mt-2"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={() => {
                  // downloadComments(videoURL);
                  // generateCSV(subtitlesData, data?.data?.video?.subtitleInfos);
                  generateCSV(subtitlesData, data?.subtitles);
                }}
                disabled={downloadCommentsLoading}
              >
                {/* Download for GPT */}
                {loading ? "loading..." : "Download for GPT"}

              </button>
            </div>
          )}

          {/* Comment Section */}
          <div className="  mt-1 lg:h-auto">
            {loading ? (
              <div className="loader-container">
                <Loader />
              </div>
            ) : (
              getResponse?.has_more == 0 &&
              subtitlesData &&
              subtitlesData?.map((items, index) => {
                return (
                  <div key={index}>
                    {/* Comment */}
                    <div className="flex items-center mt-3 pl-6">
                      <img
                        className="w-10 h-10 rounded-full mr-4"
                        src={items.user.avatar_thumb.url_list[0]}
                        alt=""
                      />
                      <div className="text-xs">
                        {/* <p className="text-gray-300 text-left">Account NAME</p> */}
                        <p className="text-gray-300 text-left">
                          {items.user.nickname}
                        </p>

                        <p className="text-gray-300 text-left pt-1">
                          {items?.text}
                        </p>
                        <div className="flex align-middle text-gray-400 pt-1 flex-wrap">
                          <p className="text-gray-400">
                            {convertTimestamp(items?.create_time)}
                          </p>
                          {/* <p className='pl-5'><FavoriteBorderIcon /> 2</p> */}
                          <p className="pl-5">
                            {items?.digg_count > 0 ? (
                              <RedHeartIcon />
                            ) : (
                              <FavoriteBorderIcon />
                            )}{" "}
                            {items?.digg_count}
                          </p>
                          <p className="text-gray-300 pl-5">Reply</p>
                        </div>
                      </div>
                    </div>

                    {/* Reply Comments */}

                    {items.reply_comment &&
                      items.reply_comment.map((reply, replyIndex) => (
                        <div
                          key={replyIndex}
                          className="flex items-center mt-3 ml-12"
                        >
                          {/* <div className='flex items-center mt-3 ml-12'> */}
                          {/* <img
                      className="w-10 h-10 rounded-full mr-4"
                      src="Images\tiktok-comment.jpg"
                      alt=""
                    /> */}
                          <img
                            className="w-10 h-10 rounded-full mr-4"
                            src={reply.user.avatar_thumb.url_list[0]}
                            alt=""
                          />
                          <div className="text-xs">
                            <p className="text-gray-300 text-left">
                              {reply.user.nickname}{" "}
                              <span className="text-red-500 pl-3">
                                {reply.label_text}
                              </span>
                            </p>
                            <p className="text-gray-300 text-left pt-1">
                              {reply.text}
                            </p>
                            <div className="flex align-middle text-gray-400 pt-1 flex-wrap">
                              <p className="text-gray-400">
                                {convertTimestamp(items?.create_time)}
                              </p>
                              <p className="pl-5">
                                {/* <RedHeartIcon /> {reply.digg_count} */}
                                {reply?.digg_count > 0 ? (
                                  <RedHeartIcon />
                                ) : (
                                  <FavoriteBorderIcon />
                                )}{" "}
                                {reply?.digg_count}
                              </p>
                              <p className="text-gray-300 pl-5">Reply</p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
